<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row align="center" justify="end">
                    <v-col cols="12" sm="6" md="4">
                        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="search" clearable
                            placeholder="Search">
                            <template slot="prepend">
                                <el-button @click="addTemplate"><i class="el-icon-plus"></i></el-button>
                            </template>

                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table class="mt-3" dense fixed-header :loading="loading" :items="notifications" :search="search"
                    :headers="headers" height="55vh" style="cursor: pointer">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-row justify="center">
                            <v-btn icon small>
                                <v-icon small @click="editItem(item)">edit</v-icon>
                            </v-btn>
                            <v-btn icon small color="danger" @click="deleteItem(item)">
                                <v-icon small>delete</v-icon>
                            </v-btn>
                        </v-row>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="modal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Manage Template
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" icon :disabled="!item.name || !item.value || !item.title" :loading="saving"
                        @click="saveTemplate"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="modal = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-select label="Category" v-model="item.category" outlined dense :items="categories"></v-select>
                    <v-text-field label="Event Name" outlined dense v-model="item.name"></v-text-field>
                    <v-text-field label="Value" outlined dense v-model="item.value"></v-text-field>
                    <v-text-field label="Title" outlined dense v-model="item.title"></v-text-field>
                    <v-textarea label="Description" outlined dense v-model="item.description"></v-textarea>
                </v-card-text>
            </v-card></v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        categories: ['Inspection', 'Tasks'],
        headers: [
            {
                text: "Action",
                align: "center",
                sortable: false,
                value: "action",
                width: "115px",
            },
            {
                text: "Category",
                value: "category",
                align: "left",
            },
            {
                text: "Name",
                value: "name",
                align: "left",
            },
            {
                text: "Value",
                value: "value",
                align: "left",
            },
            {
                text: "Title",
                value: "title",
                align: "left",
            },
            {
                text: "Description",
                value: "description",
                align: "left",
            }

        ],
        loading: false,
        modal: false,
        notifications: [],
        item: {},
        saving: false,
        search: null
    }),
    created() {
        this.getNotificationTemplates()
    },
    methods: {
        addTemplate() {
            this.item = {}
            this.modal = true
        },
        deleteItem(item) {
            this.$confirm(
                "Are you sure you want to delete this template?",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    if (item.id) {
                        await this.$API.updateNotificationTemplate({
                            id: item.id,
                            isActive: false,
                            isDeleted: true,
                        });
                    }
                    this.getNotificationTemplates();
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                })
                .catch(() => {
                    // this.$message({
                    //   type: "info",
                    //   message: "Delete canceled",
                    // });
                });
        },
        editItem(item) {
            this.item = item
            this.modal = true
        },
        async getNotificationTemplates() {
            this.loading = true
            this.notifications = await this.$API.getNotificationTemplates()
            this.loading = false
        },
        async saveTemplate() {
            this.saving = true
            if (this.item.id) {
                await this.$API.updateNotificationTemplate(this.item)
            } else {
                await this.$API.createNotificationTemplate(this.item)
            }
            this.modal = false
            this.item = {}
            this.getNotificationTemplates()
            this.saving = false

        },
    }
}
</script>